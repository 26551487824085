<template>
  <b-overlay :show="showLoading">
    <b-row>
      <b-col cols="12">
        <b-card>

          <h4>ساخت پست</h4>

          <b-row>
            <b-col
                cols="12"
                md="6"
                class="my-2"
            >
              <b-form-group
                  label="نوع مدیا"
                  label-for="type"
              >
                <v-select
                    v-model="createData.mediaFile.priority"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                />
              </b-form-group>
            </b-col>

            <b-media v-if="createData.mediaFile.priority === 2" class="my-2 col-12">
              <template >
                <b-avatar
                    class="cursor-pointer"
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                    @click.native="$refs.refInputEl1.click()"
                >
                  <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100" />
                </b-avatar>
              </template>
              <div class=" flex-wrap mt-1">
                <b-button
                    variant="primary"
                    class="d-none"
                >
                  <input
                      ref="refInputEl1"
                      type="file"
                      class="d-none"
                      accept=".jpg,.jpeg,.png"
                      @input="makeBase64Pic($event,'base64ImageSrc')"
                  >
                  <span class="d-none d-sm-inline">+</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="danger"
                    class=""
                    @click="deletePic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

<!--            <b-media v-if="createData.mediaFile.priority === 3" class="my-2 col-12">-->
<!--              <template >-->
<!--                <b-avatar-->
<!--                    v-if="base64VideoSrc === null"-->
<!--                    class="cursor-pointer"-->
<!--                    ref="previewEl2"-->
<!--                    size="300px"-->
<!--                    rounded-->
<!--                    @click.native="$refs.refInputEl2.click()"-->
<!--                >-->
<!--                  <feather-icon  icon="PlusSquareIcon" size="100" />-->
<!--                </b-avatar>-->
<!--                <video-->
<!--                    v-else-->
<!--                    :src="base64VideoSrc"-->
<!--                    autoplay-->
<!--                    style="max-height: 30rem;max-width: 100%"-->
<!--                ></video>-->
<!--              </template>-->
<!--              <div class="flex-wrap mt-1">-->
<!--                <b-button-->
<!--                    class="d-none"-->
<!--                    variant="primary"-->
<!--                >-->
<!--                  <input-->
<!--                      ref="refInputEl2"-->
<!--                      type="file"-->
<!--                      class="d-none"-->
<!--                      accept=".mp4"-->
<!--                      @input="makeBase64Pic($event,'base64VideoSrc')"-->
<!--                  >-->
<!--                  <span class="d-none d-sm-inline">+</span>-->
<!--                  <feather-icon-->
<!--                      icon="EditIcon"-->
<!--                      class="d-inline d-sm-none"-->
<!--                  />-->
<!--                </b-button>-->
<!--                <b-button-->
<!--                    variant="danger"-->
<!--                    class=""-->
<!--                    @click="deletePic()"-->
<!--                >-->
<!--                  <span class="d-none d-sm-inline">حذف</span>-->
<!--                  <feather-icon-->
<!--                      icon="TrashIcon"-->
<!--                      class="d-inline d-sm-none"-->
<!--                  />-->
<!--                </b-button>-->
<!--              </div>-->
<!--            </b-media>-->

            <b-alert
                :show="createData.mediaFile.priority === 3"
                variant="warning"
                class="col-11 mx-1"
            >
              <div class="alert-body ">
                <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                />
                <span class="ml-25">توجه داشته باشید که برای ساخت پست دارای ویدئو ابتدا باید پست را فقط همراه با کپشن بسازید و سپس برای بارگذاری ویدئو از بخش لیست پست ها پست مورد نظر را انتخاب و سپس فایل خود را بارگذاری کنید.</span>
              </div>
            </b-alert>

          </b-row>

          <!-- Address -->
          <b-form-group
              label="کپشن"
              label-for="description"
          >
            <b-form-textarea
                id="description"
                v-model="createData.content"
                placeholder="کپشن ..."
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="createPost"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from "vue-select";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {PostCreateRequest} from "@/libs/Api/Post";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
export default {
  name: "PostAdd",
  title: 'ساخت پست - پنل ادمین مکس ',
  data(){
    return{
      overlay:false,
      typeOptions:[
        {label : 'عکس' , value : 2},
        {label : 'فیلم' , value : 3}
      ],
      base64ImageSrc:null,
      base64VideoSrc:null,
      stories:[],
      createData:{
        isDeleted: false,
        postId: 0,
        userId: 0,
        content: '',
        mediaFile: {
          base64: "",
          priority: 2
        }
      },
      showLoading:false,
    }
  },
  computed:{
    priority(){
      return this.createData.mediaFile.priority;
    },
  },
  methods:{
    async createPost(){
      let _this = this;
      _this.showLoading=true;

      if (_this.createData.mediaFile.priority === 3){
        _this.createData.mediaFile = null;
      }

      _this.showLoading=true;
      let postCreateRequest = new PostCreateRequest(_this);
      postCreateRequest.setParams(_this.createData);
      await postCreateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name:'apps-post-list'})
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e,index){
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.createData.mediaFile.base64  = await _this.readAsDataURL(file);
    },
    deletePic() {
      this.base64ImageSrc = null;
      this.base64VideoSrc = null;
    },
  },
  components:{
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
  watch: {
    priority: function (val, oldVal) {
      this.deletePic();
    },
  }
}
</script>

<style scoped>

</style>